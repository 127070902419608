























import { Component, Mixins } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterDepositoryModule from '@/store/modules/master/depository'
import { CourseFolderListResource } from '@/store/types'

@Component({
  components: { Tag },
})
export default class TrainingFiles extends Mixins(NotifyMixin) {
  private get courseID() {
    return +this.$route.params.courseID
  }

  private get trainingFilesMonths() {
    return MasterDepositoryModule.trainingFilesMonth
  }

  private mounted() {
    if (!this.trainingFilesMonths.courseID || this.courseID !== this.trainingFilesMonths.courseID) {
      MasterDepositoryModule.fetchTrainingFilesMonth({ courseID: this.courseID })
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            this.$router.replace({ name: 'master.depository' })
              .catch(() => {return})
          }
          this.notifyError(err)
        })
    }
  }

  private handleNext(folder: CourseFolderListResource) {
    this.$router.push({ name: 'master.depository.item.files.month', params: { courseID: this.courseID.toString(), monthID: folder.id.toString() } })
  }
}
